<template>
	<div id="course_patrol" v-loading="loading">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">晨午检</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="head u-f-item">
				<span class="span">选择条件</span>
				<!-- <el-select size="small" v-model="condition" placeholder="请选择" style="width: 150px;" @change="changeSchool">
					<el-option v-for="item in conditionList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select> -->
				<el-select size="small" v-model="year" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeYear">
					<el-option v-for="item in yearList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="semester" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="changeSemester">
					<el-option v-for="item in semesterList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</div>
			<div class="content u-f">
				<div class="divide">
					<div class="content1">
						<div class="title u-f-item u-f-jsb">
							<div>共{{ total }}条数据</div>
							<div class="u-f-item">
								<el-select size="small" v-model="gradeId" clearable placeholder="请选择年级" style="width: 150px;margin-right: 10px;" @change="changegrade1">
									<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
								</el-select>
								<el-select size="small" v-model="classId" clearable placeholder="请选择班级" style="width: 150px;margin-right: 10px;" @change="changeclass1">
									<el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
								</el-select>
								<el-input placeholder="请输入内容" clearable v-model="sousuo" size="small" style="width: 150px;margin-right: 10px;">
									<i slot="prefix" class="el-input__icon el-icon-search"></i>
								</el-input>
								<el-button size="small" type="primary" @click="sousuoList">搜索</el-button>
								<el-button size="small" @click="addCourseShow=true">添加巡课</el-button>
								<el-button size="small" type="primary" @click="daochu">导出</el-button>
							</div>
						</div>
						<el-table :data="List" stripe style="width: 100%;border: 1px solid #EEEEEE;" height="80%">
							<el-table-column type="index" label="序号" width="80"></el-table-column>
							<el-table-column prop="date" label="日期"></el-table-column>
							<el-table-column prop="time" label="时间"></el-table-column>
							<el-table-column label="巡课类型">
								<template slot-scope="scope">
									<el-tag v-if="scope.row.type==1">{{scope.row.type_text}}</el-tag>
									<el-tag type="success" v-if="scope.row.type==2">{{scope.row.type_text}}</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="grade_name" label="年级"></el-table-column>
							<el-table-column prop="class_name" label="班级"></el-table-column>
							<el-table-column prop="should" label="应出勤"></el-table-column>
							<el-table-column prop="practical" label="实出勤"></el-table-column>
							<el-table-column label="班主任">
								<template slot-scope="scope">
									<!-- <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top-start">
									  <div>{{scope.row.reason}}</div>
									</el-tooltip> -->
									<span v-for="(item,i) in scope.row.teacher_main" :key="i">
										{{item.teacher_name}} 
										<span v-if="(i+1)!=scope.row.teacher_main.length">,</span>
									</span>
								</template>
							</el-table-column>
							<el-table-column label="任课老师">
								<template slot-scope="scope">
									<!-- <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top-start">
									  <div>{{scope.row.reason}}</div>
									</el-tooltip> -->
									<span v-for="(item,i) in scope.row.teacher_work" :key="i">
										{{item.teacher_name}}
										<span v-if="(i+1)!=scope.row.teacher_work.length">,</span>
									</span>
								</template>
							</el-table-column>
							<el-table-column label="缺勤学生">
								<template slot-scope="scope">
									<!-- <el-tooltip class="item" effect="dark" :content="scope.row.reason" placement="top-start">
									  <div>{{scope.row.reason}}</div>
									</el-tooltip> -->
									<span v-for="(item,i) in scope.row.without_student" :key="i">
										{{item.student_name}}
										<span v-if="(i+1)!=scope.row.without_student.length">,</span>
									</span>
								</template>
							</el-table-column>
							<el-table-column prop="teacher_reason" label="教师评价"></el-table-column>
							<el-table-column prop="student_reason" label="学生评价"></el-table-column>
							<el-table-column prop="remark" label="异常情况"></el-table-column>
						</el-table>
						<div class="u-f-right" style="padding: 15px 0;">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</index>
		<el-dialog title="添加巡课" :visible.sync="addCourseShow" width="600px" :before-close="handleClose">
			<div class="add_content">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						巡课类型
					</div>
					<el-radio-group v-model="type">
						<el-radio-button :label="1">晨检</el-radio-button>
						<el-radio-button :label="2">午检</el-radio-button>
					</el-radio-group>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						日期
					</div>
					<el-date-picker v-model="date" value-format="yyyy-MM-dd" type="date" placeholder="选择巡课日期"></el-date-picker>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						时间
					</div>
					<el-time-select v-model="time" :picker-options="{ start: '08:30', step: '00:15', end: '18:30' }" placeholder="选择巡课时间"></el-time-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						年级
					</div>
					<el-select v-model="grade_id" placeholder="请选择巡课年级"  @change="changegrade">
						<el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						班级
					</div>
					<el-select v-model="class_id" placeholder="请选择巡课班级"  @change="changeclass">
						<el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 10px;">
						<span>*</span>
						班主任
					</div>
					<el-select v-model="teacher_main" multiple placeholder="请选择" style="width: 70%;" filterable >
						<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 10px;">
						<span>*</span>
						任课老师
					</div>
					<el-select v-model="teacher_work" multiple placeholder="请选择" style="width: 70%;" filterable >
						<el-option v-for="item in userList" :key="item.id" :label="item.username" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						应到人数
					</div>
					<el-input v-model="should" placeholder="请输入应到人数" style="width: 40%;"></el-input>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						实到人数
					</div>
					<el-input v-model="practical" placeholder="请输入实到人数" style="width: 40%;"></el-input>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 10px;">
						缺勤学生
					</div>
					<el-select v-model="without_student" multiple placeholder="请选择" style="width: 70%;" filterable >
						<el-option v-for="item in studentList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						学生评价
					</div>
					<el-select v-model="student_status" placeholder="请选择" style="width: 70%;">
						<el-option v-for="item in student_evaluation" :key="item.id" :label="item.reason" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						老师评价
					</div>
					<el-select v-model="teacher_status"  placeholder="请选择" style="width: 70%;">
						<el-option v-for="item in teacher_evaluation" :key="item.id" :label="item.reason" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal">
						异常情况
					</div>
					<el-input type="textarea" rows="3" v-model="remark" placeholder="请输入异常情况" style="width: 70%;"></el-input>
				</div>
			</div>
			<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
				<el-button @click="closeVisible" size="small">取 消</el-button>
				<el-button type="primary" size="small" @click='submit'>确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			loading: false,
			page: 1,
			limit: 10,
			yearList: [],
			year: '',
			semesterList: [],
			semester: '',
			sousuo: '',
			total: 0,
			List: [],
			addCourseShow: false,
			date: '',
			time: '',
			type:1,
			userList:[],
			teacher_main:[],
			teacher_work:[],
			classList:[],
			class_id:'',
			teacher_evaluation:[],
			student_evaluation:[],
			teacher_status:'',
			student_status:'',
			practical:'',
			should:'',
			studentList:[],
			without_student:[],
			remark:'',
			classId:'',
			grade_id:'',
			gradeList:[],
			gradeId:''
		};
	},
	created() {},
	mounted() {
		this.getYearList();
		this.getgradeList();
		this.getUserList();
		this.getCategory()
	},
	methods: {
		// 获取年级列表
		getgradeList(){
			this.$api.setting.getGradeList({}).then(res=>{
				if(res.data.code==1){
					this.gradeList = res.data.data.rows;
				}
			})
		},
		daochu(){
			let data2 = {
				page:this.page,
				limit:this.limit
			}
			let data = {
				year_id: this.year,
				semester_id: this.semester
			};
			if(this.gradeId){
				data.grade_id = this.gradeId
			}
			if(this.classId){
				data.class_id = this.classId
			}
			if(this.sousuo){
				data2.name = this.sousuo;
			}
			data2.filter = JSON.stringify(data);
			this.$api.setting
				.coursePatrolderive(data2)
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('导出成功')
						location.href = res.data.data.url;
					}
				});
		},
		closeVisible() {
			this.init()
			this.addCourseShow = false;
		},
		handleClose(done) {
			this.init()
			done();
		},
		twacherTap(index) {
			this.tabIndex = index;
		},
		changegrade1(){
			this.classId = '';
			this.classData()
			this.page = 1;
			this.List = [];
			this.getList()
		},
		changegrade(){
			this.class_id = '';
			this.classData()
		},
		// 班级列表
		classData(){
			this.$api.setting.getClassList({filter: JSON.stringify({'grade_id':  this.grade_id||this.gradeId})}).then(res=>{
				this.classList = res.data.rows;
			})
		},
		// 学生列表
		classStudent(){
			this.$api.setting.classStudent({class_id:this.class_id}).then(res=>{
				this.studentList = res.data.data;
			})
		},
		changeclass(e){
			this.class_id = e;
			this.classStudent()
		},
		// 学年列表
		getYearList() {
			this.$api.setting.getSemesterList().then(res => {
				if (res.data.code == 1) {
					this.yearList = res.data.data;
					if (res.data.data.length) {
						if (this.yearList.filter(item => item.is_default == 2).length > 0) {
							this.year = this.yearList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.year = this.yearList[0].id;
						}
					} else {
						this.year = '';
					}
					this.getSemesterList();
				}
			});
		},
		changeYear(e) {
			this.year = e;
			this.page = 1;
			this.List = [];
			this.getSemesterList();
		},
		// 学期列表
		getSemesterList() {
			this.$api.setting.getXueQiList({ year_id: this.year }).then(res => {
				if (res.data.code == 1) {
					this.semesterList = res.data.data;
					if (res.data.data.length) {
						if (this.semesterList.filter(item => item.is_default == 2).length > 0) {
							this.semester = this.semesterList.filter(item => item.is_default == 2)[0].id;
						} else {
							this.semester = this.semesterList[0].id;
						}
					} else {
						this.semester = '';
					}
					this.getList();
				}
			});
		},
		changeSemester(e) {
			this.semester = e;
			this.page = 1;
			this.List = [];
			this.getList();
		},
		// 搜索
		sousuoList() {
			this.page = 1;
			this.List = [];
			this.getList();
		},
		changeclass1(){
			this.page = 1;
			this.List = [];
			this.getList();
		},
		// 年级列表
		getGradeList() {
			this.$api.setting.getGradeList({ filter: JSON.stringify({year_id: this.year }) }).then(res => {
				if (res.data.code == 1) {
					this.gradeList = res.data.data.rows;
					if (this.gradeList.length) {
						this.grade_id = this.gradeList[0].id;
					} else {
						this.grade_id = '';
					}
					this.page = 1;
					this.getList()
				}
			});
		},
		// 人员列表
		getUserList(){
			this.$api.setting.userList({}).then(res=>{
				if(res.data.code==1){
					this.userList = res.data.data.rows;
				}
			})
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getList();
		},
		// 获取巡课列表
		getList() {
			let data2 = {
				page:this.page,
				limit:this.limit
			}
			let data = {
				year_id: this.year,
				semester_id: this.semester
			};
			if(this.gradeId){
				data.grade_id = this.gradeId
			}
			if(this.classId){
				data.class_id = this.classId
			}
			if(this.sousuo){
				data2.name = this.sousuo;
			}
			data2.filter = JSON.stringify(data);
			this.$api.pepople
				.coursePatrolList(data2)
				.then(res => {
					if (res.data.code == 1) {
						this.List = res.data.data.rows;
						this.total = res.data.data.total;
					}
				});
		},
		// 获取评价配置列表
		getCategory(){
			this.$api.setting.getCategory({category:1}).then(res=>{
				if(res.data.code==1){
					this.student_evaluation = res.data.data.student_evaluation;
					this.teacher_evaluation = res.data.data.teacher_evaluation;
				}
			})
		},
		init(){
			this.date = '';
			this.time = '';
			this.type = 1;
			this.teacher_main = [];
			this.teacher_work = [];
			this.class_id = '';
			this.teacher_status = '';
			this.student_status = '';
			this.practical = '';
			this.should = '';
			this.remark = '';
			this.grade_id = '';
		},
		submit(){
			let data = {
				year_id:this.year,
				semester_id:this.semester,
				date:this.date,
				time:this.time,
				grade_id:this.grade_id,
				class_id:this.class_id,
				type:this.type,
				teacher_main:this.teacher_main,
				teacher_work:this.teacher_work,
				should:this.should,
				practical:this.practical,
				remark:this.remark,
				student_status:this.student_status,
				teacher_status:this.teacher_status,
				without_student:this.without_student
			}
			this.$api.pepople.addCoursePatrol(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.init()
					this.page = 1;
					this.getList()
					this.addCourseShow = false;
				}else{
					this.$message.error(res.data.msg)
				}
			})
		}
	}
};
</script>

<style lang="scss" scope>
#course_patrol {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		background-color: #ffffff;
		height: 78vh;
		margin-top: 20px;
		padding: 0 30px;
		.building-list {
			padding: 25px 25px 25px 0;
			width: 20%;
			border-right: 1px solid #eee;
			.item {
				padding: 0 15px;
				height: 40px;
				line-height: 40px;
				margin-bottom: 15px;
				background-color: #f8f8f8;
			}
			.item:hover {
				color: #007aff;
				background: #e4f1ff;
				cursor: pointer;
			}
			.active {
				color: #007aff;
				background: #e4f1ff;
			}
		}
		.divide {
			width: 100%;
			padding: 30px 30px 0 30px;
			.content1 {
				background-color: #ffffff;
				overflow-y: auto;
				height: 100%;
				.title {
					padding: 0 0 15px 0;
					.btn {
						background-color: #f5f5f5;
						padding: 5px;
					}
					.btn:hover {
						color: #4998ff;
						background: #e4f1ff;
						cursor: pointer;
					}
				}
				.el-table th > .cell,
				.el-table .cell {
					overflow: hidden; /*超出部分隐藏*/
					white-space: nowrap; /*不换行*/
					text-overflow: ellipsis; /*超出部分文字以...显示*/
				}
				.el-table thead {
					background: #fafafa !important;
				}
				.el-table th {
					padding: 15px 5px !important;
					background: #fafafa !important;
				}
				.el-table::before {
					left: 0;
					bottom: 0;
					width: 100%;
					height: 0;
				}
				.el-table .btn {
					padding: 5px;
					text-align: center;
				}
			}
		}
	}
	.el-dialog__header {
		background-color: #f8f8f8;
	}
	.el-dialog__body {
		padding: 20px 20px 50px;
		height: 500px;
		overflow-y: auto;
		.add_content {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					width: 100px;
					text-align: right;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.type-item {
					background-color: #f3f3f3;
					padding: 8px 20px;
					margin-right: 15px;
					border-radius: 5px;
				}
				.typeActive {
					background-color: #d3eaff;
					color: #339dff;
				}
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
</style>
